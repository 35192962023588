@media screen and (min-width: 640px){
    .weight-loss::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        border-right: 12rem solid white;
        border-top: 18rem solid transparent;
        height: 0;
        width: 0;
    }
}
@media screen and (min-width:1024px) { 
    .weight-loss::after{
        border-right: 19rem solid white;
        border-top: 16rem solid transparent;
    }
}
@media screen and (min-width:1440px){
    .weight-loss::after { 
        border-top: 65rem solid transparent;
        border-right: 32rem solid white;
    }
}